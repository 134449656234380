export const topMVSlider = () => {
  $(function () {
    function mv(duration) {
      let time = 0;
      let mvFrame = document.querySelector(".js-mv-slider");
      let mvItem = mvFrame.querySelectorAll(".js-slider-frame");
      const sliderItemCount = 2;
      let eleStart = 0;
      mvFrame.querySelectorAll(".js-mv-slider__img").forEach((ele) => {
        ele.addEventListener("transitionend", () => {
          if (ele.classList.contains("fade")) {
            ele.classList.remove("fade");
          }
          if (ele.classList.contains("active")) {
            ele.classList.remove("active");
            ele.classList.add("current");
          }
        });
      });

      const reset = function () {
        mvFrame.querySelectorAll(".js-mv-slider__img").forEach((ele) => {
          if (ele.classList.contains("fade")) {
            ele.classList.remove("fade");
          }
          if (ele.classList.contains("active")) {
            ele.classList.remove("active");
            ele.classList.add("current");
          }
        });
      };

      const animate = function () {
        if (eleStart >= sliderItemCount) {
          eleStart = 0;
        }
        mvItem.forEach((ele) => {
          ele.querySelectorAll(".js-mv-slider__img").forEach((ele, index) => {
            if (index === eleStart) {
              ele.classList.add("fade");
              ele.classList.remove("current");
            }
            if (index === (eleStart + 1 >= sliderItemCount ? 0 : eleStart + 1)) {
              ele.classList.add("active");
            }
            // ele.nextSibling
          });
        });
        eleStart++;
      };
      function timer(timestamp) {
        if (timestamp - time > duration) {
          time = timestamp;
          reset();
          animate();
        }
        window.requestAnimationFrame(timer);
      }
      window.requestAnimationFrame(timer);
    }

    mv(4000);
  });
};
