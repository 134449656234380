export const shopInfoSlider = () => {
  $(function () {
    const $jsShopSlider = $(".js-shopinfo-slider");

    $jsShopSlider.slick({
      speed: 1000,
      autoplay: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      appendArrows: $(".p-concept__slider-arrow"),
      prevArrow: '<div class="p-concept__arrow-prev"></div>',
      nextArrow: '<div class="p-concept__arrow-next"></div>',

      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
      ],
    });
  });
};
