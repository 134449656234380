import { inview } from "./assets/js/modules/inview.js";
import { hambergerToggle } from "./assets/js/modules/hamburgertoggle.js";
import { pageTopBtnAction } from "./assets/js/modules/pagetopbtn-action.js";
import { headerAction } from "./assets/js/modules/header-action.js";
import { accordion } from "./assets/js/modules/accordion.js";
import { initPcNav } from "./assets/js/modules/pcNav.js";
import { spNavDropToggle } from "./assets/js/modules/spDropToggle.js";
import { resizeAction } from "./assets/js/modules/resize-action.js";
import { cookie } from "./assets/js/modules/cookie.js";
import { loadingAni } from "./assets/js/modules/loadding-ani.js";
import { topMVSlider } from "./assets/js/modules/topMVslider.js";
import { topShopSlider } from "./assets/js/modules/top-shop-slider.js";
import { topCatcy } from "./assets/js/modules/topCatcy.js";
// company_history
import { company_history } from "./assets/js/modules/company.js";
// factory_faq
import { factory_faq } from "./assets/js/modules/factory.js";
// contact
import { contact } from "./assets/js/modules/contact.js";
// contact factory
import { contact_factory } from "./assets/js/modules/contact-factory.js";
// contact option
import { contact_option } from "./assets/js/modules/contact-option.js";
// contact interview
import { contact_interview } from "./assets/js/modules/contact-interview.js";

import { shopInfoSlider } from "./assets/js/modules/shopinfo-slider.js";

const $body = $("body");

spNavDropToggle();
initPcNav();
inview();
hambergerToggle();
pageTopBtnAction();
headerAction();
cookie();

company_history();
accordion();
factory_faq();

if ($body.hasClass("top")) {
  loadingAni();
  resizeAction();
  topCatcy();
  topMVSlider();
  topShopSlider();
}
if ($("main").hasClass("l-pcontact")) {
  contact();
}
if ($("main").hasClass("l-pcontact-factory")) {
  contact_factory();
}

if ($("main").hasClass("l-pcontact-option")) {
  contact_option();
}
if ($("main").hasClass("l-pcontact-interview")) {
  contact_interview();
}

if ($body.hasClass("shopinfo")) {
  shopInfoSlider();
}
