export const accordion = () => {
  $(function () {
    $(".js-accordion__ttl").each(function () {
      $(this).click(function () {
        $(this).toggleClass("is-open");
        $(this).next().stop().slideToggle();
      });
    });
  });
};
