export const contact_factory = () => {
    $(function () {
        function checkLocalStorge() {
            if (
                !localStorage.getItem('checkThanksFactory') &&
                $('.p-contact-form').hasClass('p-contact-form-thanks')
            ) {
                window.location.href = '../contact_factory/';
                return false;
            } else {
                localStorage.removeItem('checkThanksFactory');
            }
        }

        $(document).ready(function () {
            checkLocalStorge();
        });
        var wpcf7Elm = document.querySelector(
            '.p-contact-form-factory .wpcf7-form'
        );

        wpcf7Elm.addEventListener(
            'wpcf7mailsent',
            function (event) {
                setTimeout(() => {
                    window.location.href = '../contact_factory/thanks';
                }, 3000);
                localStorage.setItem(
                    'checkThanksFactory',
                    'checkThanksFactory'
                );
            },
            false
        );
    });
};
