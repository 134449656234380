export const headerAction = () => {
  $(function () {
    const $body = $("body");
    $(window).on("load scroll", function () {
      let scrollPos = $(this).scrollTop();

      if (scrollPos > 100) {
        $body.addClass("is-header-small");
      } else {
        $body.removeClass("is-header-small");
      }
    });
  });
};
