export const contact_interview = () => {
    $(function () {
        function checkLocalStorge() {
            if (
                !localStorage.getItem('checkThanksInterview') &&
                $('.p-contact-form').hasClass('p-contact-form-thanks')
            ) {
                window.location.href = '../contact_interview/';
                return false;
            } else {
                localStorage.removeItem('checkThanksInterview');
            }
        }

        $(document).ready(function () {
            checkLocalStorge();
            setPositonErrorMessage();
            removePositonErrorMessage();
            changeFile();
        });
        var wpcf7Elm = document.querySelector(
            '.p-contact-form-interview .wpcf7-form'
        );

        wpcf7Elm.addEventListener(
            'wpcf7mailsent',
            function (event) {
                setTimeout(() => {
                    window.location.href = '../contact_interview/thanks';
                }, 3000);
                localStorage.setItem(
                    'checkThanksInterview',
                    'checkThanksInterview'
                );
            },
            false
        );
        function setPositonErrorMessage() {
            $('.c-form__file__error').remove();
            if ($('.c-form__file .wpcf7-not-valid-tip').length) {
                const el = $('.c-form__file .wpcf7-not-valid-tip')
                    .clone()
                    .addClass('c-form__file__error');
                $('.c-form__file').closest('.c-form__group').append(el);
            }
        }
        function removePositonErrorMessage() {
            $('.c-form__file__error').remove();
        }
        function changeFile() {
            $(".c-form__file input[type='file']").on('change', function () {
                $(".c-form__file input[type='file']").css('opacity', '1');
                setTimeout(setPositonErrorMessage, 100);
            });
        }
    });
};
