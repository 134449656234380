export const pageTopBtnAction = () => {
  const body = $("body");
  const speed = 1000;

  $(window).on("load", function () {
    //移動アニメーション

    // ページ遷移後
    const { hash } = window.location;
    if (hash) {
      $("body,html").stop().scrollTop(0);

      let headerH;

      if (body.hasClass("shopinfo")) {
        headerH = $(".l-header-shopinfo__inner").height();
      } else {
        headerH = $(".l-header__inner").height();
      }

      const { top } = $(hash).offset();
      $("html, body").animate(
        {
          scrollTop: top - headerH * 1.4 + 5,
        },
        speed
      );
    }
  });
  // ページトップボタンの制御
  // 表示の記述
  function pageTop() {
    let scrollY = window.scrollY;

    if (!body.hasClass("is-nav-open")) {
      if (scrollY !== 0) {
        $(".c-page-top-btn").addClass("is-show");
      } else {
        $(".c-page-top-btn").removeClass("is-show");
      }
    }
  }

  $(window).on("load scroll", function () {
    pageTop();
  });

  // スムーススクロール
  $('a[href^="#"]').click(function () {
    if (!$(this).hasClass(".js-page-top")) {
      const windowSize = window.innerWidth;
      const href = $(this).attr("href");

      let headerH;

      if (body.hasClass("shopinfo")) {
        if (windowSize >= 768) {
          headerH = 0;
        } else {
          headerH = $(".l-header-shopinfo__inner").height();
          body.removeClass("is-nav-open");
        }
      } else {
        headerH = $(".l-header__inner").height();
      }

      const target = $(href == "#" || href == "" ? "html" : href);

      const position = target.offset().top;
      $("html, body").animate(
        {
          scrollTop: position - headerH,
        },
        speed
      );
    }
    return false;
  });
};
