export const company_history = () => {
    const history_table__button = $('.history-table__buttons .nav-link');
    const history_table__tab = $('.history-table__tab');

    history_table__button.on('click', function () {
        const _ = $(this);
        const target = $(_.attr('data-href'));
        if (target.length > 0) {
            if (!_.hasClass('is-open')) {
                // open
                history_table__tab.removeClass('is-open')
                history_table__button.removeClass('is-open')

                _.addClass('is-open');
                target.addClass('is-open');
            }
            return 0;
        }
    });
}