export const factory_faq = () => {
    const faq_box = $('.faq-box');
    const faq_box_q = $('.faq-box-q');
    faq_box.map((a, b) => {
        const _ = $(b);
        if (!_.hasClass('is-open')) {
            const faq_box_a = _.find('.faq-box-a');
            faq_box_a.slideUp();
        }
    })
    faq_box_q.on('click', function () {
        const _ = $(this);
        const parent = _.parent('.faq-box');
        const faq_box_a = parent.find('.faq-box-a');
        if (!parent.hasClass('is-open')) {
            // update
            parent.addClass('is-open');
            _.addClass('is-open');
            faq_box_a.slideDown();
        } else {
            // reset
            parent.removeClass('is-open');
            _.removeClass('is-open');
            faq_box_a.slideUp();
        }
    });
}