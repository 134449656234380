export const topShopSlider = () => {
    $(function () {
        const $jsShopSlider = $('.js-shop-slider');

        $jsShopSlider.slick({
            speed: 1000,
            autoplay: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            appendArrows: $('.p-top-shop__slider-arrow'),
            prevArrow: '<div class="c-shop-slider__arrow-prev"></div>',
            nextArrow: '<div class="c-shop-slider__arrow-next"></div>',

            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });
    });
};
