export const resizeAction = () => {
  $(function () {
    // JavaScript
    const thresholdWidth = 768; // ウィンドウサイズの閾値（768px以上の場合に再読み込みを行う）

    let currentWidth = window.innerWidth;

    function handleResize() {
      const newWidth = window.innerWidth;

      // ウィンドウサイズが閾値を超えたかどうかをチェック
      if (currentWidth <= thresholdWidth && newWidth > thresholdWidth) {
        // ウィンドウサイズが767px以下から768px以上に変更された場合の処理をここに記述
        location.reload();
      }

      // ウィンドウサイズを更新
      currentWidth = newWidth;
    }

    // ページ読み込み時にウィンドウサイズを初期化
    handleResize();

    // ウィンドウのリサイズイベントを監視
    window.addEventListener("resize", handleResize);
  });
};
